export default async function fetchData(/* params: { domain: string | undefined } */) {
  try {
    // const data = await import('assets/json/db.json')
    // return parseData(data.cellas[0], (data as any).payment)
    return {}
  } catch (err) {
    console.log('err')
  }
}

// function parseData(data: any, payment: any) {
//   const {
//     config_json: config,
//     product_json: product,
//     feedbacks_json: reviews,
//     color_config: styleConfig = {}
//   } = data
//
//   config.phone = '+1 (203) 457-8171'
//   config.announcementBarText = config.annoucementBarText
//   config.shopId = data.shop_id
//   config.discountCode = data.discount_name
//   config.discountPercent = data.discount
//   config.freeShipping = data.free_shipping
//   config.discountType = data.discount_type || null
//
//   const env = {
//     paypalClientId: data.production_env?.VUE_APP_PAYPAL_CLIENT_ID
//   }
//
//   const meta = {
//     favicon: data.favicon,
//     title: data.production_env?.VUE_APP_TITLE,
//     description: data.production_env?.VUE_APP_DESCRIPTION,
//     image: data.production_env?.VUE_APP_IMAGE,
//     url: data.production_env?.VUE_APP_URL,
//     // eslint-disable-next-line camelcase
//     googleVerificationId: data.production_env?.google_verification_id
//     // googleVerificationId: data.production_env?.VUE_APP_GOOGLE_VERIFICATION_ID
//   }
//
//   const policies = [
//     { label: 'Shipping', html: data.shipping },
//     { label: 'Taxes', html: data.taxes },
//     { label: 'Returns', html: data.returns }
//   ].filter((item) => item.html)
//
//   // Convert sorted properties in array to object
//   // Reason: Don't want to change logic of current source code
//   const sortedProperties = product.sorted_properties
//   if (Array.isArray(sortedProperties) && sortedProperties.length) {
//     const properties: Types.PlainObject = {}
//     sortedProperties.forEach((prop: any) => {
//       properties[prop.name] = prop.values
//     })
//
//     product.properties = properties
//   }
//
//   product.variants.map((variant: any) => {
//     variant.isSellable = variant.allow_to_sell
//     return variant
//   })
//
//   const style: Types.Style = {
//     primaryColor: styleConfig.primary_color,
//     secondaryColor: styleConfig.secondary_color,
//     textColor: styleConfig.text_color,
//     backgroundColor: styleConfig.background_color,
//     logoWidth: styleConfig.logo_width
//   }
//
//   const checkoutOptions = getCheckoutOptions(data, config.shopId, payment.enablePaypalExpress)
//
//   return { config, env, meta, product, policies, reviews, style, payment, checkoutOptions }
// }

// function getCheckoutOptions(data: any, shopId: number | string, enablePaypalExpress: boolean) {
//   let paypal = false
//   let creditCard = false
//
//   if (enablePaypalExpress) {
//     paypal = true
//   } else if (data.production_env?.NEXT_ENV_CHECKOUT_ENABLE_PAYPAL === 'true') {
//     paypal = true
//   } else {
//     paypal = false
//   }
//
//   if (isIdInList(data.production_env?.NUXT_ENV_CHECKOUT_CREDIT_CARD_BLACKLIST, shopId)) {
//     creditCard = false
//   } else if (isIdInList(data.production_env?.NUXT_ENV_CHECKOUT_CREDIT_CARD_WHITELIST, shopId)) {
//     creditCard = true
//   } else if (data.production_env?.NUXT_ENV_CHECKOUT_ENABLE_CREDIT_CARD === 'true') {
//     creditCard = true
//   } else {
//     creditCard = false
//   }
//
//   return {
//     paypal,
//     creditCard
//   }
// }

// function isIdInList(list: any, shopId: any) {
//   return (list?.replace(/\[|\]/g, '').split(',') || []).includes(`${shopId}`)
//   // return true
// }
