import * as Sentry from '@sentry/node'
import getConfig from 'next/config'
import { Integrations } from '@sentry/tracing'
import { CaptureConsole, RewriteFrames } from '@sentry/integrations'

const isBrowser = () => {
  return typeof window !== 'undefined'
}

export const initSentry = () => {
  const sentryDsn = isBrowser() ? getConfig().publicRuntimeConfig.sentryDsn : process.env.Sentry__Dsn
  const sentryEnvironment = isBrowser() ? getConfig().publicRuntimeConfig.env : process.env.env
  const buildVersion = isBrowser() ? getConfig().publicRuntimeConfig.buildVersion : process.env.BUILD_VERSION

  if (sentryDsn) {
    console.log('error')
    let integrations = [];
    const rewriteFrame = new RewriteFrames({
      iteratee: (frame: any) => {
        frame.filename = frame.filename.replace(
          process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
          'app:///'
        )
        frame.filename = frame.filename.replace('.next', '_next')
        return frame
      }
    })

    if (process.env.NEXT_IS_SERVER === 'true' && process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR) {
      // For Node.js, rewrite Error.stack to use relative paths, so that source
      // maps starting with ~/_next map to files in Error.stack with path
      // app:///_next
      integrations = [
        new Integrations.BrowserTracing(),
        new Integrations.Express(),
        new CaptureConsole({
          levels: ['error', 'warn']
        }),
        rewriteFrame
      ]
    }
    else {
      integrations = [
        new Integrations.BrowserTracing(),
        new Integrations.Express(),
        new CaptureConsole({
          levels: ['error', 'warn']
        })
      ]
    }

    Sentry.init({
      integrations,
      dsn: sentryDsn,
      environment: sentryEnvironment == 'production' ? 'prd' : sentryEnvironment,
      release: buildVersion
    })
  }
}
