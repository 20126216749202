import React from 'react'
import Head from 'next/head'
import dompurify from 'dompurify'
import { shopHelpPath } from '@/helpers'
import getConfig from 'next/config'
type MyHeadProps = {
  meta: Types.Meta
}

const gtmId_GA4 = getConfig().publicRuntimeConfig.gtmId || ''


const AppHead: React.FC<MyHeadProps> = ({ meta }) => {
  const sanitizer = dompurify.sanitize

  return (
    <Head>
      <title>{meta.title || 'ShopHelp™'}</title>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-WEQVE4VMTJ" />
      {process.browser && (
        <script
          dangerouslySetInnerHTML={{
            __html: sanitizer(`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-WEQVE4VMTJ');`)
          }}
        />
      )}
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtmId_GA4}');`
        }}
      ></script> 
      
      <link rel="shortcut icon" href={shopHelpPath('shophelp-favicon.svg')} />
      <link rel="apple-touch-icon" href={shopHelpPath('shophelp-favicon.svg')} />
      <meta
        key="viewport"
        name="viewport"
        content="width=device-width, initial-scale=1, user-scalable=no"
      />
      <meta key="mobile-web-app-capable" name="mobile-web-app-capable" content="yes" />
      <meta
        key="apple-mobile-web-app-capable"
        name="apple-mobile-web-app-capable"
        content="false"
      />
      <meta
        key="apple-mobile-web-app-title"
        name="apple-mobile-web-app-title"
        content={meta.title || 'ShopHelp'}
      />
      <meta key="description" name="description" content={meta.description || 'ShopHelp'} />
      <meta key="theme-color" name="theme-color" content="#1B1B1B" />
      <meta key="og:type" name="og:type" content="website" />
      <meta key="og:title" name="og:title" content={meta.title || 'ShopHelp'} />
      <meta key="og:site_name" name="og:site_name" content={meta.title || 'ShopHelp'} />
      <meta key="og:description" name="og:description" content={meta.description || 'ShopHelp'} />
      <meta key="og:image" name="og:image" content={meta.image || ''} />
      <meta key="og:url" name="og:url" content={meta.url || ''} />
      <meta name="google-site-verification" content={meta.googleVerificationId || ''} />
    </Head>
  )
}

export default AppHead
