import { isEmpty } from '@/helpers'
import ReactGA from 'react-ga'

export interface IGa {
  pageview: (url: any) => any
  event: (evt: any) => any
}

export interface IGaContainer {
  ga?: IGa | null
}

const gaContainer: IGaContainer = {}

export default function useGa(trackingIds?: string[]): IGaContainer {
  if (!trackingIds || !isEmpty(gaContainer)) {
    return gaContainer // singleton
  }
  if (!Array.isArray(trackingIds)) {
    trackingIds = [trackingIds]
  }
  // initialize
  const trackingList = trackingIds?.map((id, index) => {
    return {
      trackingId: id,
      gaOptions: { name: `tracker${index}` }
    }
  })
  ReactGA.initialize(trackingList)
  const trackingNames = trackingList.map((v) => v.gaOptions.name)
  gaContainer.ga = {
    pageview: (url: any) => ReactGA.pageview(url, trackingNames),
    event: (evt: any) => ReactGA.pageview(evt, trackingNames)
  }
  return gaContainer
}
