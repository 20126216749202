import { isEmpty } from '@/helpers'
import logRocket from 'logrocket'
import getConfig from 'next/config'

const enabledEnvironments = ['prd', 'dev']
const env = getConfig().publicRuntimeConfig.env || 'local'

export interface ILogRocketConfig {
  fullSession?: boolean
  id?: string
}

export interface ILogRocketContainer {
  logRocket: ILogRocket
}

export interface ILogRocket {
  init: (forceInit?: boolean) => void
  identify: (user: Types.PlainObject) => void
  track: (event: string) => void
}

const config: ILogRocketConfig = {}

const options = {
  console: {
    isEnabled: false
  }
}

function isReady() {
  const url = logRocket.sessionURL

  if (config.id && url?.includes(config.id)) {
    return true
  }

  return false
}

const LogRocketContainer = {} as ILogRocketContainer

export default function useLogRocket(config?: ILogRocketConfig): ILogRocketContainer {
  if (!config || !isEmpty(LogRocketContainer)) {
    return LogRocketContainer // singleton
  }

  const identify = (user: Types.PlainObject = {}) => {
    if (!enabledEnvironments.includes(env)) {
      return null
    }
    logRocket.getSessionURL(() => {
      const name = `${user.firstName || ''} ${user.lastName || ''}`.trim()
      logRocket.identify(user.email, {
        name,
        email: user.email
      })
    })
  }

  const init = (forceInit = false) => {
    if (!config.id || isReady()) {
      return null
    }
    if (!config.fullSession && !forceInit) {
      return null
    }
    logRocket.init(config.id, options)
  }

  const track = (event: string) => {
    if (!enabledEnvironments.includes(env)) {
      return null
    }

    logRocket.getSessionURL(() => {
      logRocket.track(event)
    })
  }

  LogRocketContainer.logRocket = {
    identify,
    init,
    track
  }

  return LogRocketContainer
}
