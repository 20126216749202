import React, { FC, useEffect } from 'react'
import { NotiProvider } from 'src/components/shared/Notification/NotiContext'
import { SubscribeStateKey, subscribeState, unsubscribeState } from '@helpers/stateSubscriber'

type LayoutProps = {
  styleConfig: Types.Style
}
const DefaultLayout: FC<LayoutProps> = ({ children }) => {
  useEffect(() => {
    subscribeState(SubscribeStateKey.DISABLE_GLOBAL_SCROLL, (modalActive: boolean) => {
      document.documentElement.style.overflow = modalActive ? 'hidden' : 'auto'
    })
    return () => {
      unsubscribeState(SubscribeStateKey.DISABLE_GLOBAL_SCROLL)
    }
  }, [])

  return <NotiProvider>{children}</NotiProvider>
}

export default DefaultLayout
