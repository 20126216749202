import { shopHelpPath } from '@/helpers'
import getConfig from 'next/config'
import Head from 'next/head'
import css from 'styled-jsx/css'

const config = getConfig().publicRuntimeConfig

export const UnderMaintenance = () => {
  const iframeUrl = `${config.cdnBasePath}t/l/under-maintenance`
  const title = 'System Under Maintenance'
  return (
    <div className="under-maintenance">
      <Head>
        <title>{title}</title>
        <link rel="shortcut icon" href={shopHelpPath('shophelp-favicon.svg')} />
        <link rel="apple-touch-icon" href={shopHelpPath('shophelp-favicon.svg')} />
      </Head>
      <iframe src={iframeUrl} title={title} frameBorder="0"></iframe>
      <style jsx>{style}</style>
    </div>
  )
}

const style = css`
  .under-maintenance {
    width: 100vw;
    height: 100vh;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
`
